import React from 'react';
import styled from 'styled-components';

const ButtonContainer = styled<any>('button')`
  padding: ${(props) => props.padding || '0'};
  margin: ${(props) => props.margin || '0'};
  background: ${(props) => props.btnStyle?.background || '#fff'};
  color: ${(props) => props.btnStyle?.color || '#000'};
  border: 1px solid ${(props) => props.btnStyle?.color || '#000'};
  border-radius: 9999px;
  cursor: pointer;
  text-align: center;
  display: inline-block;
  &:disabled {
    opacity: 0.25;
    pointer-events: none;
  }

  &:hover {
    background: ${(props) => props.btnStyle?.backgroundHover || '#000'};
    color: ${(props) => props.btnStyle?.colorHover || '#fff'};
    border: 1px solid ${(props) => props.btnStyle?.colorHover || '#fff'};
  }
`;

interface Style {
  background: string;
  backgroundHover: string;
  color: string;
  colorHover: string;
}

export default function Button({
  children,
  onClick,
  type,
  padding,
  margin,
  disabled,
  hidden,
  form,
  btnStyle,
}: {
  children: React.ReactNode;
  onClick?: () => void;
  type?: string;
  padding?: string;
  margin?: string;
  disabled?: boolean;
  hidden?: boolean;
  form?: string;
  btnStyle?: Style;
}) {
  if (hidden) return <div></div>;

  return (
    <ButtonContainer
      onClick={onClick}
      type={type}
      margin={margin}
      padding={padding}
      disabled={disabled}
      form={form}
      btnStyle={btnStyle}
    >
      {children}
    </ButtonContainer>
  );
}
