import { configureStore } from '@reduxjs/toolkit';
import loadingReducer from './stores/loadingStore';
import userReducer from './stores/userStore';
import tradeInReducer from './stores/tradeInStore';
import configReducer from './stores/configStore';

export default configureStore({
  reducer: {
    loading: loadingReducer,
    tradeIn: tradeInReducer,
    user: userReducer,
    config: configReducer,
  },
});
