import axios from 'axios';
import { VERSION } from 'src/configs/featuresFlags';

export interface BasicOption<T = number> {
  label: string;
  value: T;
}

export interface InfoAutoResponse {
  id: number;
  list_price: boolean;
  name: string;
  prices: boolean;
  prices_from: number;
  prices_to: number;
  summary: string;
}

const BASE = `${process.env.REACT_APP_INFOAUTO_URL}`;

export async function getBrands() {
  const brands = await getFromInfoauto(
    `${process.env.REACT_APP_INFOAUTO_URL}/brands?`
  );

  const responseData = brands
    .filter((brand: any) => Boolean(brand.prices))
    .map((brand: any) => ({
      value: brand.name,
      label: brand.name,
      id: brand.id,
    }));

  return responseData;
}

export async function getModels(
  brand: number | undefined,
  year?: number | undefined
) {
  let requestUrl = `${process.env.REACT_APP_INFOAUTO_URL}/models?brand_id=${brand}&`;
  if (year) {
    requestUrl += `year=${year}&`;
  }

  const models = await getFromInfoauto(requestUrl);
  if (VERSION.isLegacy) {
    let responseData = models
      .filter((model: any) => Boolean(model.prices))
      .map((model: any) => ({
        value: model.name,
        label: model.name,
        id: model.id,
      }));
    return responseData;
  } else {
    if (year !== undefined) {
      let responseData = models
        .filter(
          (model: any) =>
            model.prices === true &&
            model.prices_from <= year &&
            year <= model.prices_to
        )
        .map((model: any) => ({
          value: model.name,
          label: model.name,
          id: model.id,
        }));

      return responseData;
    }
  }
}

export async function getVersions(brand: number, group: number, year?: number) {
  const versions = await getFromInfoauto(
    `${process.env.REACT_APP_INFOAUTO_URL}/versions?brand_id=${brand}&group_id=${group}&year=${year}&`
  );
  const responseData = versions
    .filter((version: any) => Boolean(version.prices))
    .map((version: any) => ({
      value: version.description,
      label: version.description,
      years: generateYears(version.prices_from, version.prices_to),
      codia: version.codia,
    }));

  return responseData;
}

export async function getPrice(
  codia: number,
  year: number,
  km: number,
  configId: string
) {
  const clientId = configId;
  const res = await axios.post(
    `${process.env.REACT_APP_INFOAUTO_URL}/prices${
      (clientId && `?config_id=${clientId}`) || ''
    }`,
    {
      codia,
      year,
      km,
    }
  );

  const price = {
    minimumPrice: res.data.min,
    maximumPrice: res.data.max,
    averagePrice: res.data.prom,
  };

  return price;
}

export async function getYearsFromBrand(id: number): Promise<BasicOption[]> {
  const url = `${BASE}/models?brand_id=${id}&`;

  const rawYears: InfoAutoResponse[] = await getFromInfoauto(url);

  let min: number[] = [];
  let max: number[] = [];

  let years: BasicOption[] = [];

  const filteredRawYears = rawYears.filter(
    (brand) => brand.prices_from && brand.prices_to
  );

  filteredRawYears.forEach((year) => {
    min.push(year.prices_from);
    max.push(year.prices_to);
  });

  years = generateYears(Math.min(...min), Math.max(...max));

  return years;
}

async function getFromInfoauto(url: string): Promise<any> {
  let page = 1;
  let arrayCounter = 0;
  let arrayData: any = [];

  await axios.get(`${url}page=${page}&page_size=99`).then((res) => {
    arrayCounter = res.data.length;
    arrayData.push(...res.data);
  });

  while (arrayCounter === 99) {
    page++;
    await axios.get(`${url}page=${page}&page_size=99`).then((res) => {
      arrayCounter = res.data.length;
      arrayData.push(...res.data);
    });
  }

  return arrayData;
}

function generateYears(yearFrom: number, yearTo: number): BasicOption[] {
  let years = [];

  for (let i = yearFrom; i <= yearTo; i++) {
    years.push({
      value: i,
      label: String(i),
    });
  }

  return years;
}

export function removeRepeatValues<T = unknown>(values: T[]): T[] {
  let newArr: T[] = [];

  values.forEach((value) => {
    let alreadyExist = newArr.includes(value);

    if (!alreadyExist) {
      newArr.push(value);
    }
  });

  return newArr;
  // NOTE: Set not supported.
  // return [...new Set(values)];
}
