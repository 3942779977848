import { createSlice } from '@reduxjs/toolkit';

export const loadingSlice = createSlice({
  name: 'loading',
  initialState: {
    value: true,
  },
  reducers: {
    loadingActivate: (state) => {
      state.value = true;
    },
    loadingDeactivate: (state) => {
      state.value = false;
    },
  },
});

export const { loadingActivate, loadingDeactivate } = loadingSlice.actions;

export default loadingSlice.reducer;
