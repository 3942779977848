import { useState } from 'react';
import styled from 'styled-components';

const TextFieldContainer = styled.div``;

const InputContainer = styled.label`
  display: flex;
  border-radius: 0.5rem;
  height: 40px;
  border: 1px solid #cccccc;
  ${(props: { disabled: boolean }) =>
    props.disabled
      ? `
        background-color: #e6e6e6;
        opacity: 0.7;
      `
      : ''}
`;

const InputPrefix = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e6e6e6;
  border-right: 1px solid #cccccc;
  border-radius: 0.5rem 0 0 0.5rem;
  width: 5rem;
  max-width: 5rem;
`;

const PrefixImage = styled.img`
  max-width: 90%;
  max-height: 80%;
`;

const PrefixText = styled.span`
  font-size: small;
  padding: 0.5rem 0px;
`;

const InputText = styled.input`
  font-size: small;
  border: none;
  height: 22px;
  width: 100%;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: transparent;
  padding: 1.25rem 0;
  margin-left: 1rem;
  margin-right: 1rem;
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:focus {
    outline: none;
  }
`;

const Label = styled.p`
  font-size: small;
  text-align: left;
  margin: 0;
  margin-left: 0.25rem;
  margin-bottom: 0.125rem;
  color: #58595b;
`;
const Warning = styled.p`
  height: 1rem;
  color: red;
  text-align: left;
  font-size: x-small;
  padding: 0;
  margin: 0.25rem 0 0 0.25rem;
`;
export default function TextField({
  name,
  type = 'text',
  label,
  prefix,
  placeholder = '',
  filter,
  required = false,
  register,
  errors,
  disabled = false,
  limits,
  errorMsg = 'Caractéres inválidos',
  pattern,
}: {
  name: string;
  type?: string;
  label?: string;
  prefix?: Record<string, string>;
  placeholder?: string;
  filter?: RegExp;
  required: boolean;
  register?: any;
  errors: Record<string, Record<string, any>>;
  disabled: boolean;
  limits?: {
    min: {
      value: number;
      errorMsg: string;
    };
    max: {
      value: number;
      errorMsg: string;
    };
  };
  errorMsg?: string;
  pattern?: RegExp;
}) {
  const [oldValue, setOldValue] = useState('');
  const handleChange = (e: any) => {
    if (pattern && !pattern.test(e.target.value)) {
      e.target.value = oldValue;
      return;
    }
    setOldValue(e.target.value);
  };

  const validate: Record<string, any> = {
    required: { value: required, message: 'Campo vacio' },
    pattern: { value: filter, message: errorMsg },
  };

  if (limits) {
    validate.min = {
      value: limits.min.value,
      message: (errorMsg =
        limits.min.errorMsg || `Cantidad mínima ${limits.min.value}`),
    };
    validate.max = {
      value: limits.max.value,
      message: (errorMsg =
        limits.max.errorMsg || `Cantidad máxima ${limits.max.value}`),
    };
    validate.valueAsNumber = true;
  }

  return (
    <TextFieldContainer>
      {label && <Label>{label}</Label>}
      <InputContainer disabled={disabled}>
        {prefix && (
          <InputPrefix>
            {prefix.type === 'text' ? (
              <PrefixText>{prefix.content}</PrefixText>
            ) : (
              <PrefixImage src={prefix.content} alt={prefix.content} />
            )}
          </InputPrefix>
        )}
        <InputText
          type={type}
          placeholder={placeholder}
          {...register(name, { ...validate })}
          disabled={disabled}
          onInput={handleChange}
        />
      </InputContainer>
      <Warning>{errors?.[name]?.message}</Warning>
    </TextFieldContainer>
  );
}
