import { createSlice } from '@reduxjs/toolkit';

export const tradeInSlice = createSlice({
  name: 'tradeIn',
  initialState: {
    value: {},
  },
  reducers: {
    writingVehicle: (state, vehicle) => {
      state.value.vehicle_data = vehicle.payload;
    },
    writingPrice: (state, price) => {
      state.value.prices = price.payload;
    },
    writingOptions: (state, options) => {
      state.value.options = options.payload;
    },
    resetVehicleValue: (state) => {
      state.value = {};
    },
  },
});

export const {
  writingVehicle,
  writingPrice,
  writingOptions,
  resetVehicleValue,
} = tradeInSlice.actions;

export default tradeInSlice.reducer;
