import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    value: {},
  },
  reducers: {
    writingUser: (state, user) => {
      state.value = user.payload;
    },
    resetUser: (state) => {
      state.value = {};
    },
  },
});

export const { writingUser, resetUser } = userSlice.actions;

export default userSlice.reducer;
