import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { getPrice } from '../../utils/tradeIn';

import CalculatorView from './CalculatorView';
import UsedForm from '../UsedForm';

import Button from '../Button';
import Form from '../Form';
import Step from '../Step';
import Stepper from '../Stepper';
import Spinner from '../Spinner';
import Logo from '../Logo';
import Checkbox from '../Checkbox';

import { loadingActivate, loadingDeactivate } from '../../stores/loadingStore';
import {
  writingVehicle,
  writingPrice,
  resetVehicleValue,
} from '../../stores/tradeInStore';
import { writingUser, resetUser } from '../../stores/userStore';

const Card = styled.div`
  background-color: #fff;
  box-shadow: 2px 2px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  max-width: 500px;
  min-height: 600px;
  text-align: center;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
`;

const ButtonContainer = styled.div`
  margin: auto;
`;
const CTAButton = styled.button`
  width: auto;
  height: 48px;
  justify-content: center;
  background-color: ${(props) => props.theme.primaryColor};
  color: white;
  border: 1px solid #fff;
  border-radius: 9999px;
  cursor: pointer;

  padding-left: 5rem;
  padding-right: 5rem;
  font-weight: 600;
  &:hover {
    background-color: ${(props) => props.theme.primaryColorHover};
  }
`;

const Label = styled.p`
  font-size: small;
  text-align: left;
  margin: 0;
  margin-left: 0.25rem;
  margin-bottom: 0.25rem;
  text-align: center;
  color: #58595b;
  margin-top: 5px;
`;

const CompletedDescriptionContainer = styled.div`
  margin: 0;
  font-size: 1rem;
`;

export default function TradeIn() {
  const Dispatch = useDispatch();

  const [validForm, setValidForm] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [stepComputed, setStepComputed] = useState(0);
  const [isChecked, setIsChecked] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [fields, setFields] = useState(null);

  const isLoading = useSelector((state) => state.loading.value);
  const user_data = useSelector((state) => state.user.value);
  const config_data = useSelector((state) => state.config.value);
  const config_id = useSelector((state) => state.config.id);
  const utm_conf = useSelector((state) => state.config.value.available_utm);

  const { vehicle_data, prices } = useSelector((state) => state.tradeIn.value);

  useEffect(() => {
    if (utm_conf) {
      const newParams = new URLSearchParams();
      newParams.set('utm_source', '');
      newParams.set('utm_medium', 'social');
      newParams.set('utm_content', 'direct-ongoing');
      newParams.set('utm_campaign', 'blog');
      // Reemplaza la URL actual con los nuevos parámetros
      window.history.replaceState(
        {},
        '',
        `${window.location.pathname}?${newParams.toString()}`
      );
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (config_data?.steps[stepComputed - 1]?.submit) {
        await sendCurrentData();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepComputed]);

  // check if config data is loaded and the current step has a propery 'fields'
  // if so, set the fields state to the current step fields
  // if not, set the fields state to null
  useEffect(() => {
    if (config_data && currentStep < config_data.steps.length) {
      if (config_data.steps[currentStep - 1].fields) {
        const orderedFields = [
          ...config_data.steps[currentStep - 1].fields,
        ].sort((a, b) => a.order - b.order);

        setFields(orderedFields);
      } else {
        setFields(null);
      }
    } else {
      setFields(null);
    }
  }, [config_data, currentStep]);

  // check validForm and checkboxConfirm if both are true, set isDisabled to false
  // this will enable the continue button
  useEffect(() => {
    if (!validForm) return setIsDisabled(true);
    if (config_data.steps[stepComputed]?.checkboxConfirm && !isChecked)
      return setIsDisabled(true);
    setIsDisabled(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChecked, validForm, currentStep]);

  // stepComputed is the current step for array indexing
  useEffect(() => {
    setStepComputed(currentStep - 1);
  }, [currentStep]);

  const btnPrimary = {
    background: config_data.style?.primaryColor || '#000000',
    backgroundHover: config_data.style?.primaryColorHover || '#999999',
    color: '#fff',
    colorHover: '#fff',
  };

  const btnSecondary = {
    background: config_data.style?.secondaryColor || '#000000',
    backgroundHover: config_data.style?.secondaryColorHover || '#999999',
    color: config_data.style?.secondaryTextColor || '#000',
    colorHover: config_data.style?.secondaryTextColorHover || '#fff',
  };

  const handleFormVehicle = async (vehicle) => {
    Dispatch(loadingActivate());
    const { codia, year, kilometers } = vehicle;
    const vehiclePrice = await getPrice(codia, year, kilometers, config_id);
    Dispatch(writingVehicle(vehicle));
    Dispatch(writingPrice(vehiclePrice));
    changeStep();
    Dispatch(loadingDeactivate());
  };

  const handleFormUser = async (values) => {
    Dispatch(writingUser(values));
    changeStep();
  };

  const handleConfirm = async () => {
    Dispatch(loadingActivate());
    Dispatch(loadingDeactivate());
    changeStep();
  };

  function changeStep() {
    setIsChecked(false);
    setValidForm(false);
    setCurrentStep(currentStep + 1);
  }

  function handleCTA(type, url) {
    const functions = {
      link: () => {
        url && window.open(url, '_blank');
      },
      reset: () => {
        Dispatch(resetVehicleValue());
        Dispatch(resetUser());
        setCurrentStep(1);
      },
    };
    functions[type]();
  }

  const sendCurrentData = async () => {
    Dispatch(loadingActivate());

    const data = {
      vehicle_data,
      prices,
      user_data,
    };
    try {
      await fetch(config_data.steps[stepComputed - 1].submit.url, {
        method: config_data.steps[stepComputed - 1].submit.method,
        headers: config_data.steps[stepComputed - 1].submit.headers,
        body: JSON.stringify(data),
      });
    } catch (error) {
      console.log(error);
    } finally {
      Dispatch(loadingDeactivate());
    }
  };

  const btnStyle = {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'end',
  };

  return (
    <Card>
      {isLoading && <Spinner></Spinner>}

      <Logo logoUrl={config_data.logo} />
      <Stepper
        step={currentStep}
        maxStep={config_data.steps.length}
        activeColor={config_data.style?.primaryColor}
      />

      <Step step={1} currentStep={currentStep}>
        {config_data.steps[stepComputed]?.title && (
          <Step.Title>{config_data.steps[stepComputed]?.title}</Step.Title>
        )}
        {config_data.steps[stepComputed]?.description && (
          <Step.Body>{config_data.steps[stepComputed]?.description}</Step.Body>
        )}
        {config_data.steps[stepComputed]?.formTitle && (
          <Step.FormTitle>
            {config_data.steps[stepComputed]?.formTitle}
          </Step.FormTitle>
        )}
        <UsedForm
          handleForm={handleFormVehicle}
          setValidForm={setValidForm}
          kilometers={config_data.steps[0].fieldSettings.kilometers}
          disabledFields={config_data.steps[0].disabledFields}
          additionalFields={config_data.steps[0]?.additionalFields}
        ></UsedForm>
        <Step.Footer>
          {config_data.steps[0].checkboxConfirm && (
            <Checkbox
              setIsChecked={setIsChecked}
              data={config_data.steps[0].checkboxConfirm}
            />
          )}
          <div style={btnStyle}>
            <Button
              padding={'.5rem 2rem'}
              type="submit"
              form="used-form"
              disabled={isDisabled}
              btnStyle={btnPrimary}
            >
              {config_data.steps[stepComputed]?.button?.label || 'Calcular'}
            </Button>
          </div>
        </Step.Footer>
      </Step>

      <Step step={2} currentStep={currentStep}>
        {config_data.steps[stepComputed]?.title && (
          <Step.Title>{config_data.steps[stepComputed].title}</Step.Title>
        )}
        {config_data.steps[stepComputed]?.description && (
          <Step.Body>{config_data.steps[stepComputed].description}</Step.Body>
        )}
        {config_data.steps[stepComputed]?.formTitle && (
          <Step.FormTitle>
            {config_data.steps[stepComputed].formTitle}
          </Step.FormTitle>
        )}
        <Form
          fields={fields && fields}
          formTitle={config_data.steps[1].formTitle}
          id="register-form"
          handleForm={handleFormUser}
          setValidForm={setValidForm}
        />
        <Step.Footer>
          <div style={btnStyle}>
            <Button
              padding={'.5rem 1rem'}
              margin={'0 .375rem'}
              btnStyle={btnSecondary}
              onClick={() => setCurrentStep(currentStep - 1)}
            >
              {config_data.steps[stepComputed]?.textBack || 'Volver'}
            </Button>
            <Button
              padding={'.5rem 2rem'}
              form="register-form"
              type="submit"
              disabled={isDisabled}
              btnStyle={btnPrimary}
            >
              {config_data.steps[stepComputed]?.button?.label || 'Confirmar'}
            </Button>
          </div>
        </Step.Footer>
      </Step>

      <Step step={3} currentStep={currentStep}>
        {config_data.steps[stepComputed]?.title && (
          <Step.Title>{config_data.steps[stepComputed].title}</Step.Title>
        )}
        {config_data.steps[stepComputed]?.description && (
          <Step.Body>{config_data.steps[stepComputed].description}</Step.Body>
        )}
        {config_data.steps[stepComputed]?.formTitle && (
          <Step.FormTitle>
            {config_data.steps[stepComputed].formTitle}
          </Step.FormTitle>
        )}
        <>
          <CalculatorView
            disabledFields={config_data.steps[0].disabledFields}
          />
          <Step.Footer>
            <div style={btnStyle}>
              <Button
                padding={'.5rem 1rem'}
                margin={'0 .375rem'}
                btnStyle={btnSecondary}
                onClick={() => setCurrentStep(currentStep - 1)}
              >
                {config_data.steps[stepComputed]?.textBack || 'Volver'}
              </Button>
              <Button
                onClick={handleConfirm}
                padding={'.5rem 2rem'}
                btnStyle={btnPrimary}
              >
                {config_data.steps[stepComputed]?.button?.label || 'Confirmar'}
              </Button>
            </div>
          </Step.Footer>
        </>
      </Step>

      <Step step={4} currentStep={currentStep}>
        <Step.Title>{config_data.completedStep.title}</Step.Title>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            flex: 1,
          }}
        >
          <svg
            width="70"
            height="70"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M35 70C54.33 70 70 54.33 70 35C70 15.67 54.33 0 35 0C15.67 0 0 15.67 0 35C0 54.33 15.67 70 35 70Z"
              fill={config_data?.style?.primaryColor || 'black'}
            />
            <path
              d="M45.0263 24.8887L31.3833 38.3697L25.4903 32.5467L21.7773 36.2167L29.5273 43.8747L31.3833 45.6297L33.2403 43.8747L48.7403 28.5577L45.0263 24.8887Z"
              fill="white"
            />
          </svg>
          <h2
            style={{
              fontSize: '2rem',
              marginTop: '0.5rem',
            }}
          >
            {config_data.completedStep?.textThanks}
          </h2>
          <Step.Body>
            <CompletedDescriptionContainer>
              {config_data.completedStep.description}
            </CompletedDescriptionContainer>
          </Step.Body>
        </div>
        {config_data.completedStep.CTA && (
          <ButtonContainer>
            <CTAButton
              onClick={() =>
                handleCTA(
                  config_data.completedStep.CTA.type,
                  config_data.completedStep.CTA.url
                )
              }
            >
              {config_data.completedStep.CTA.label}
            </CTAButton>
            <Label>{config_data.completedStep.CTA.description}</Label>
          </ButtonContainer>
        )}
      </Step>
    </Card>
  );
}
