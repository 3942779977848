import { ReactNode } from 'react';
import styled from 'styled-components';
import { devices } from '../utils/devices';

const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1 1 auto;
`;

const StepTitle = styled.h2`
  font-size: 1.5rem;
  margin-top: 0;
  font-weight: bold;
  color: #171d24;
  margin-bottom: 0.8rem;
`;

const StepBody = styled.div`
  font-size: 0.8rem;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0.8rem;
`;

const StepFormTitle = styled.h3`
  font-size: 1rem;
  font-weight: 600;
  color: #171d24;
  margin-bottom: 0.8rem;
  margin-top: 0;
  text-align: left;

  @media ${devices.mobileL} {
    font-size: 1.125rem;
  }
`;

const StepFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: auto;

  @media ${devices.mobileL} {
    flex-direction: row;
    align-items: center;
  }
`;

type StepProps = {
  step: number;
  currentStep: number;
  children?: ReactNode;
};

const Step = ({ step, currentStep, children }: StepProps) => {
  if (step !== currentStep) return <></>;
  return <StepContainer>{children}</StepContainer>;
};

Step.Title = ({ children }: { children: ReactNode }) => (
  <StepTitle>{children}</StepTitle>
);
Step.Body = ({ children }: { children: ReactNode }) => (
  <StepBody>{children}</StepBody>
);
Step.FormTitle = ({ children }: { children: ReactNode }) => (
  <StepFormTitle>{children}</StepFormTitle>
);
Step.Footer = ({ children }: { children: any }) => {
  const footer = {
    justifyContent: children.length > 1 ? 'space-between' : 'end',
  };

  return <StepFooter style={footer}>{children}</StepFooter>;
};

export default Step;
