import './App.css';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setStepperConfig, setConfigId } from './stores/configStore';
import { config } from './configs/config';
import ErrorPage from './components/ErrorPage';
import TradeIn from './components/tradeIn';
import LoadingBar from './components/LoadingBar';
import { createGlobalStyle, ThemeProvider } from 'styled-components';

const FontStyles = createGlobalStyle`
${(props) => {
  if (!props.fonts || props.fonts.length === 0) return;
  const fontsToDisplay = [];
  props.fonts.forEach((e) => {
    const font = `
        @font-face {
          font-family: ${e.name};
          src: url(${e.url}) format('${e.format}');
          font-weight: ${e.weight};
          font-style: ${e.style};
          font-display: ${e.display};
        }
      `;
    fontsToDisplay.push(font);
  });
  return fontsToDisplay.join(' ');
}}

#widget-trade-in *{
  box-sizing: border-box;
}

#widget-trade-in {
  font-family:${(props) =>
    props.fontName && props.fontName + ','} sans-serif !important;
  font-size: 16px !important;
  line-height: 1.5 !important;
  color: #000 !important;
  background-color: #fff !important;
  max-width: 500px !important;
}
`;

function App() {
  const Dispatch = useDispatch();
  const config_data = useSelector((state) => state.config.value);
  const [theme, setTheme] = useState({
    primaryColor: '#000000',
    secondaryColor: '#aaaaaa',
    primaryColorHover: '#444444',
    secondaryColorHover: '#2c2c2c',
  });
  const [loading, setLoading] = useState(false);

  const {
    NODE_ENV: ENVIROMENT,
    REACT_APP_CONFIG_URL: CONFIG_URL,
    REACT_APP_DB_URL: DB_URL,
    REACT_APP_VERCEL: MODE_VERCEl,
  } = process.env;
  // Fetch config data from the server or local
  useEffect(() => {
    async function setConfig() {
      let url; // URL used for fetch config

      if (ENVIROMENT === 'development') {
        if (!CONFIG_URL) {
          setTheme({ ...theme, ...config.style });
          return Dispatch(setStepperConfig(config));
        }
        url = CONFIG_URL;
      }

      // URL for Vercel APP
      if (CONFIG_URL && MODE_VERCEl) url = CONFIG_URL;

      const root = document.getElementById('widget-trade-in');
      const configIdAttribute = root.attributes.getNamedItem('configId')?.value;
      if (configIdAttribute && DB_URL) {
        // URL for Production deployment
        const configId = root.attributes.getNamedItem('configId').value;
        url = DB_URL + '?config_id=' + configId;
      }

      try {
        setLoading(true);
        await getConfig(url);
      } catch (error) {
        console.error({ setConfig: error });
      } finally {
        setLoading(false);
      }
    }
    if (config_data === null) setConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getConfig(url) {
    try {
      const response = await fetch(url, {
        method: 'GET',
      });
      const data = await response.json();
      if (!data) throw new Error('No data');
      data && data.steps.sort((a, b) => a.order - b.order);

      setTheme({ ...theme, ...data.style });
      Dispatch(setStepperConfig(data));
      Dispatch(setConfigId(data.id));
    } catch (error) {
      console.error({ getConfig: error });
      if (ENVIROMENT === 'development') {
        Dispatch(setConfigId(config.id));
        Dispatch(setStepperConfig(config));
        setTheme({ ...theme, ...config.style });
      }
    }
  }

  if (loading) return <LoadingBar />;

  if (config_data === null && !loading) return <ErrorPage />;

  return (
    <ThemeProvider theme={theme}>
      <FontStyles
        fonts={config_data.style.fonts}
        fontName={config_data.style.fontName}
      ></FontStyles>
      <TradeIn />
    </ThemeProvider>
  );
}

export default App;
