import { BsCheck } from 'react-icons/bs';
import styled from 'styled-components';
import { devices } from '../utils/devices';

const StepperContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 200px;
  margin: 1rem auto 0.5rem 0;
  opacity: ${(props: { complete: boolean }) => (props.complete ? '0.5' : '1')};
  @media ${devices.mobileL} {
    margin: 1rem auto 1rem auto;
  }
`;

const StepCircle = styled.div`
  border: #d8d8d8 4px solid;
  background-color: #d8d8d8;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  z-index: 3;
  padding: 0.6rem;
  & > p {
    transform: translateY(-1px);
    font-size: 0.8rem;
    font-weight: 400;
    padding: 0;
    margin: 0;
    line-height: 0;
  }
  & > span {
    font-size: 1.4rem;
  }
`;

const Step = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  &:before {
    position: absolute;
    content: '';
    border-bottom: 2px solid #d8d8d8;
    top: 50%;
    right: -30%;
    left: 70%;
    z-index: 2;
  }
  &:after {
    position: absolute;
    content: '';
    border-bottom: 2px solid
      ${(props: { active: boolean; color: string }) =>
        (props.active && props.color) || '#d8d8d8'};
    transition: right 0.5s ease-in-out;
    top: 50%;
    right: ${(props: { active: boolean }) => (props.active ? '-30%' : '30%')};
    left: 70%;
    z-index: 3;
  }

  &:last-child::after {
    content: none;
  }
  &:last-child:before {
    content: none;
  }
`;

interface Props {
  step: number;
  maxStep: number;
  activeColor: string;
}

const Stepper = ({ step, maxStep, activeColor }: Props) => {
  const steps = [];
  const stepActive = {
    borderColor: activeColor,
    backgroundColor: 'transparent',
  };
  const stepDone = {
    backgroundColor: activeColor,
    borderColor: activeColor,
  };

  for (let i = 1; i <= maxStep; i++) {
    steps.push(
      <Step key={i} active={step > i} color={activeColor}>
        <StepCircle
          style={step > i ? stepDone : step === i ? stepActive : undefined}
        >
          {step > i ? (
            <span>
              <BsCheck color="white" size={'1.25rem'} />
            </span>
          ) : (
            <p>{i}</p>
          )}
        </StepCircle>
      </Step>
    );
  }

  return <StepperContainer complete={step > maxStep}>{steps}</StepperContainer>;
};

export default Stepper;
