import { createSlice } from '@reduxjs/toolkit';

export const configSlice = createSlice({
  name: 'config',
  initialState: {
    value: null,
    id: null,
  },
  reducers: {
    setStepperConfig: (state, config) => {
      state.value = config.payload;
    },
    setConfigId: (state, config) => {
      state.id = config.payload;
    }
  },
});

export const { setStepperConfig, setConfigId } = configSlice.actions;

export default configSlice.reducer;
