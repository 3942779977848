export const config = {
  completedStep: {
    title: 'Todo Listo',
    description:
      'Ya tenés la cotización en tu mail, ya podés coordinar la inspección de tu auto.',
    textThanks: '¡Gracias!',
    CTA: {
      type: 'reset',
      label: 'Volver a cotizar',
      description: '¿Quieres volver a cotizar un vehículo usado?',
      url: 'https://google.com.ar',
    },
  },
  style: {
    primaryColor: '#eb0a1e',
    secondaryColor: '#FFFFFF',
    primaryColorHover: '#a10715',
    secondaryColorHover: '#000000',
    fontName: 'Toyota Type',
    fonts: [
      {
        url: 'https://widget-assets.simplitec.io/kansai/ToyotaType-SemiboldIt.woff2',
        name: 'Toyota Type',
        format: 'woff2',
        weight: 600,
        style: 'italic',
        display: 'swap',
      },
      {
        url: 'https://widget-assets.simplitec.io/kansai/ToyotaType-Semibold.woff2',
        name: 'Toyota Type',
        format: 'woff2',
        weight: 600,
        style: 'normal',
        display: 'swap',
      },
      {
        url: 'https://widget-assets.simplitec.io/kansai/ToyotaType-LightIt.woff2',
        name: 'Toyota Type',
        format: 'woff2',
        weight: 100,
        style: 'italic',
        display: 'swap',
      },
      {
        url: 'https://widget-assets.simplitec.io/kansai/ToyotaType-Light.woff2',
        name: 'Toyota Type',
        format: 'woff2',
        weight: 100,
        style: 'normal',
        display: 'swap',
      },
      {
        url: 'https://widget-assets.simplitec.io/kansai/ToyotaType-Italic.woff2',
        name: 'Toyota Type',
        format: 'woff2',
        weight: 400,
        style: 'italic',
        display: 'swap',
      },
      {
        url: 'https://widget-assets.simplitec.io/kansai/ToyotaType-Black.woff2',
        name: 'Toyota Type',
        format: 'woff2',
        weight: 900,
        style: 'normal',
        display: 'swap',
      },
      {
        url: 'https://widget-assets.simplitec.io/kansai/ToyotaType-BlackIt.woff2',
        name: 'Toyota Type',
        format: 'woff2',
        weight: 900,
        style: 'italic',
        display: 'swap',
      },
      {
        url: 'https://widget-assets.simplitec.io/kansai/ToyotaType-Bold.woff2',
        name: 'Toyota Type',
        format: 'woff2',
        weight: 800,
        style: 'normal',
        display: 'swap',
      },
      {
        url: 'https://widget-assets.simplitec.io/kansai/ToyotaType-BoldIt.woff2',
        name: 'Toyota Type',
        format: 'woff2',
        weight: 800,
        style: 'italic',
        display: 'swap',
      },
      {
        url: 'https://widget-assets.simplitec.io/kansai/ToyotaType-Regular.woff2',
        name: 'Toyota Type',
        format: 'woff2',
        weight: 400,
        style: 'normal',
        display: 'swap',
      },
    ],
  },
  logo: 'https://rn-40-prod.s3.amazonaws.com/kai_26ce0e00d0.png',
  steps: [
    {
      name: 'calculator',
      title: 'Vendé tu auto en KANSAI',
      description: '',
      formTitle: 'Datos del auto a cotizar',
      order: 1,
      additionalFields: [
        {
          name: 'radicacion',
          label: 'Radicación',
          placeholder: 'Seleccione una opción',
          required: true,
          errorMessage: 'El nombre no es válido',
          type: 'select',
          options: [
            {
              value: 'CABA',
              label: 'CABA',
            },
            {
              value: 'Buenos Aires',
              label: 'Buenos Aires',
            },
            {
              value: 'Cordoba',
              label: 'Cordoba',
            },
          ],
          order: 1,
        },
        {
          name: 'nombre',
          label: 'Nombre',
          placeholder: 'ej. Juan',
          required: true,
          filter: '^[ a-zA-ZÀ-ÿ\u00f1\u00d1]{0,50}$',
          pattern: '^[ a-zA-ZÀ-ÿ\u00f1\u00d1]{0,50}$',
          type: 'text',
          content: 'field',
          order: 1,
        },
      ],
      fieldSettings: {
        kilometers: {
          max: {
            value: 157000,
            errorMsg: 'Aceptamos usados hasta 157000 km',
          },
          min: {
            value: 1,
            errorMsg: 'No aceptamos vehiculos 0 km',
          },
        },
      },
      checkboxConfirm: {
        label: 'Acepto',
        link: {
          label: 'términos y condiciones',
          url: 'https://google.com.ar',
        },
      },
    },
    {
      title: 'Completá tus datos',
      description: 'Y recibí al instante una cotización personalizada',
      formTitle: 'Compartinos algunos datos de contacto',
      textBack: 'Volver',
      // submit: {
      //   url: 'https://nathan-stg.simplitec.io/webhook/e62f0728-46c3-4dc3-ae47-8dd1c1249736',
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json',
      //   },
      // },
      fields: [
        {
          name: 'nombre',
          label: 'Nombre',
          placeholder: 'ej. Juan',
          required: true,
          filter: '^[ a-zA-ZÀ-ÿ\u00f1\u00d1]{0,50}$',
          pattern: '^[ a-zA-ZÀ-ÿ\u00f1\u00d1]{0,50}$',
          type: 'text',
          content: 'field',
          order: 1,
        },
        {
          name: 'apellido',
          label: 'Apellido',
          placeholder: 'ej. Perez',
          required: true,
          filter: '^[ a-zA-ZÀ-ÿ\u00f1\u00d1]{0,50}$',
          pattern: '^[ a-zA-ZÀ-ÿ\u00f1\u00d1]{0,50}$',
          type: 'text',
          content: 'field',
          order: 2,
        },
        {
          name: 'mail',
          label: 'Mail',
          placeholder: 'ej. email@gmail.com',
          required: true,
          filter:
            "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$",
          type: 'text',
          content: 'field',
          fieldSettings: {
            errorMessage: 'Formato inválido (email@gmail.com)',
          },
          order: 3,
        },
        {
          name: 'telefono',
          label: 'Telefono',
          placeholder: 'ej. 1127153322',
          required: true,
          filter: '^[0-9]{5,10}$',
          pattern: '^[0-9]{0,10}$',
          type: 'text',
          fieldSettings: {
            errorMessage: 'Formato inválido (1122334455)',
          },
          content: 'field',
          prefix: {
            type: 'text',
            content: '+54 9',
          },
          order: 4,
        },
        {
          name: 'radicacion-del-vehiculo',
          label: 'Radicacion del vehiculo',
          required: true,
          content: 'select',
          options: [
            'Buenos Aires',
            'CABA',
            'Catamarca',
            'Chaco',
            'Chubut',
            'Cordoba',
            'Corrientes',
            'Entre Rios',
            'Formosa',
            'Jujuy',
            'La Pampa',
            'La Rioja',
            'Mendoza',
            'Misiones',
            'Neuquen',
            'Rio Negro',
            'Salta',
            'San Juan',
            'San Luis',
            'Santa Cruz',
            'Santa Fe',
            'Santiago del Estero',
            'Tierra del Fuego',
            'Tucuman',
          ],
          placeholder: 'Seleccione una provincia',
          order: 5,
        },
        {
          name: 'concesionario',
          label: 'Selecciona el concesionario',
          required: true,
          content: 'select',
          options: ['Kansai Villa Luro', 'Kansai Caballito', 'Kansai Palermo'],
          placeholder: 'Seleccione una provincia',
          order: 6,
        },
      ],
    },
    {
      title: 'Calculadora',
      formTitle: 'Resultados de la cotización',
      textBack: 'Volver',
      //   submit: {
      //     url: 'https://nathan-stg.simplitec.io/webhook/a8bf40e4-d42b-4cfc-b9ac-e1db2d4d896b',
      //     method: 'POST',
      //     headers: {
      //       'Content-Type': 'application/json',
      //     },
      //   },
    },
  ],
  available_utm:false
};
