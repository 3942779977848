import styled from 'styled-components';

const AnimatedCard = styled.div`
  background-color: #fff;
  box-shadow: 2px 2px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  max-width: 500px;
  min-height: 600px;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: sans-serif;
`;

const LoadingDots = styled.span`
  display: inline-block;
  font-size: 2rem;

  &:nth-child(2) {
    animation: dots 0.37s infinite alternate linear;
  }

  &:nth-child(3) {
    animation: dots 0.4s infinite alternate linear;
  }

  &:nth-child(4) {
    animation: dots 0.4s infinite alternate linear;
  }
  @keyframes dots {
    from {
      color: red;
      transform: translate(0, -10%);
    }
    to {
      color: black;
      transform: translate(0, 0);
    }
  }
`;

const LoadingTitle = styled.h2`
  margin: 0;
  line-height: 0;
`;

export default function LoadingBar() {
  return (
    <>
      <AnimatedCard>
        <LoadingTitle>Cargando</LoadingTitle>
        <LoadingDots>.</LoadingDots>
        <LoadingDots>.</LoadingDots>
        <LoadingDots>.</LoadingDots>
      </AnimatedCard>
    </>
  );
}
