import styled from 'styled-components';
import { devices } from '../utils/devices';
import { ReactComponent as Error } from './static/images/Error.svg';

const Card = styled.div`
  background-color: #fff;
  box-shadow: 2px 2px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  max-width: 500px;
  min-height: 600px;
  text-align: center;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: 2rem;

  @media ${devices.mobileL} {
    min-height: 550px;
    max-height: 550px;
  }
`;

const Container = styled.div`
  width: 20%;
`;
const ErrorMessage = styled.h2`
  font-size: 2rem;
  margin-top: 0.5rem;
  color: #d75a4a;
`;
export default function ErrorPage() {
  return (
    <Card>
      <Container>
        <Error />
      </Container>
      <ErrorMessage>Error al obtener la información</ErrorMessage>
    </Card>
  );
}
