import styled from 'styled-components';
import { BsChevronDown } from 'react-icons/bs';

interface Option {
  value: any;
  label: string;
}

const SelectContainer = styled.div``;

const Label = styled.p`
  font-size: small;
  text-align: left;
  color: #58595b;
  margin-left: 0.25rem;
  margin-bottom: 0.125rem;
  margin-top: 0;
`;
const Warning = styled.p`
  height: 1rem;
  color: red;
  text-align: left;
  font-size: x-small;
  padding: 0;
  margin: 0.25rem 0 0 0.25rem;
`;
const SelectInput = styled.select`
  height: 40px;
  width: 100%;
  border-radius: 4px;
  padding: 0.25rem 1rem;
  font-size: small;
  border: 1px solid #cccccc;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: transparent;
  &:disabled {
    background-color: #e6e6e6;
  }
  border-radius: 8px;
  outline: none;
`;
const IconContainer = styled.div`
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  width: 15px;
  ${(props: { disabled: boolean }) => (props.disabled ? 'opacity: 0.5;' : '')}
`;
export default function Select({
  label,
  options,
  defaultValue,
  placeholder = '',
  disabled = false,
  register,
  errors,
  name,
  handleSelect,
}: {
  label?: string;
  options: Option[] | string[];
  defaultValue?: any;
  placeholder?: string;
  disabled?: boolean;
  register?: any;
  errors: any;
  name: string;
  handleSelect?: (value: any, name: string) => void;
}) {
  const listOptions = options.map((option) => {
    let value, label;
    if (typeof option === 'string') {
      label = option;
      value = option;
    } else ({ value, label } = option);

    return (
      <option key={value} value={value}>
        {label}
      </option>
    );
  });

  return (
    <SelectContainer>
      {label && <Label>{label}</Label>}
      <div
        style={{
          position: 'relative',
        }}
      >
        <SelectInput
          disabled={disabled}
          defaultValue={defaultValue}
          {...register(name, {
            required: { value: true, message: 'Seleccione una opción' },
            onChange: (e: any) => {
              if (handleSelect) {
                handleSelect(e.target.value, name);
              }
            },
          })}
        >
          {placeholder && <option value="">{placeholder}</option>}
          {listOptions}
        </SelectInput>
        <IconContainer disabled={disabled}>
          <BsChevronDown size="100%" />
        </IconContainer>
      </div>
      <Warning>{!disabled && errors?.[name]?.message}</Warning>
    </SelectContainer>
  );
}
