import { useForm, SubmitHandler } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadingActivate, loadingDeactivate } from '../stores/loadingStore';
import { writingOptions, resetVehicleValue } from '../stores/tradeInStore';
import {
  getBrands,
  getModels,
  getVersions,
  getYearsFromBrand,
} from '../utils/tradeIn';
import TextField from './TextField';
import Select from './Select';
import styled from 'styled-components';
import { devices } from '../utils/devices';
import { VERSION } from 'src/configs/featuresFlags';

export const Grid = styled.form`
  margin-bottom: 1rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(auto, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;

  @media ${devices.mobileL} {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 1rem;
    grid-row-gap: 0.25 rem;
  }
`;

export const FullWidthRow = styled.div`
  grid-column: 1 / -1;
`;

export const Title = styled.h2`
  font-weight: 700;
  font-size: 25px;
  line-height: 25px;
  text-align: center;
`;

export const Description = styled.h4`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
`;

export const FormTitle = styled.h2`
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  margin-left: 0.5rem;
`;

export default function UsedForm({
  setValidForm,
  handleForm,
  kilometers,
  disabledFields,
  additionalFields,
}: any) {
  const {
    register,
    handleSubmit,
    watch,
    resetField,
    setValue,
    formState: { errors, isValid },
  } = useForm<any>({ mode: 'onChange' });

  const onSubmit: SubmitHandler<any> = (data) => {
    const formRes = { ...data };

    formRes['year'] = Number(data.year);
    formRes['codia'] = Number(codia);

    const options = {
      brands: brandOptions,
      models: modelOptions,
      versions: versionOptions,
      years: yearOptions,
    };

    Dispatch(writingOptions(options));
    handleForm(formRes);
  };

  interface Options {
    value: string;
    label: string;
    id: number;
    versions?: [];
    years?: [];
    codia?: number;
  }

  const Dispatch = useDispatch();
  const tradeIn = useSelector((state: any) => state.tradeIn.value);

  let options: Options[] = [];
  const [brand, setBrand] = useState(0);
  const [brandVehicleData, setBrandVehicleData] = useState(0);
  const [modelVehicleData, setModelVehicleData] = useState(0);
  const [yearVehicleData, setYearVehicleData] = useState(0);
  const [codia, setCodia] = useState(0);
  const [brandOptions, setBrandOptions] = useState(options);
  const [modelOptions, setModelOptions] = useState(options);
  const [versionOptions, setVersionOptions] = useState(options);
  const [yearVersions, setYearVersions] = useState(0);
  const [yearOptions, setYearOptions] = useState(options);
  const patentRegexp =
    /(^[a-zA-Z]{3}[0-9]{3}$)|(^[a-zA-Z]{2}[0-9]{3}[a-zA-Z]{2}$)/;
  const numberRegexp = /^[0-9]{0,7}$/;

  useEffect(() => {
    if (Object.keys(tradeIn).length === 0) {
      if (brandOptions.length === 0) {
        (async () => {
          setBrandOptions(await getBrands());
          Dispatch(loadingDeactivate());
        })();
      }
    } else {
      const { options, vehicle_data } = tradeIn;
      setBrandOptions(options.brands);
      setModelOptions(options.models);
      setVersionOptions(options.versions);
      setYearOptions(options.years);

      if (vehicle_data) {
        const brand = brandOptions.find(
          (el) => el.value === vehicle_data?.brand
        );
        if (brand) {
          setBrandVehicleData(brand?.id);
        }
        setYearVehicleData(vehicle_data?.year);
      }

      Dispatch(loadingDeactivate());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  useEffect(() => {
    if (Object.keys(tradeIn).length > 0) {
      const { vehicle_data } = tradeIn;
      for (const key in vehicle_data) {
        setValue(key, vehicle_data[key]);
      }
      setCodia(vehicle_data.codia);
      setValidForm(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandOptions, modelOptions, versionOptions, yearOptions]);

  useEffect(() => {
    setValidForm(isValid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid]);

  const handleSelect = async (value: any, name: string) => {
    Dispatch(loadingActivate());
    Dispatch(resetVehicleValue());
    let year;
    if (name === 'brand') {
      const brand = brandOptions.find((el) => el.value === value);

      if (brand) {
        setBrand(brand.id);

        if (!VERSION.isLegacy) {
          const YEARS_AVAILABLE = await getYearsFromBrand(brand.id);

          const YEARS_OPTIONS: Options[] = YEARS_AVAILABLE.map(
            ({ value, label }, i) => {
              return {
                id: i,
                label,
                value: String(value),
              };
            }
          );

          setYearOptions(YEARS_OPTIONS);
        } else {
          const models = await getModels(brand.id);

          setModelOptions(models);
        }

        resetField('model');
        resetField('version');
        resetField('year');
      } else if (brandVehicleData !== 0) {
        const models = await getModels(brandVehicleData);

        setModelOptions(models);
        resetField('model');
        resetField('version');
        resetField('year');
      }
    }

    if (name === 'year') {
      if (brand !== 0) {
        await getYearsFromBrand(brand);
        year = value;
        setYearVersions(year);
        const models = await getModels(brand, year);
        setModelOptions(models);
        resetField('model');
        resetField('version');
      } else if (brandVehicleData !== 0) {
        await getYearsFromBrand(brandVehicleData);
        year = value;

        setYearVersions(year);
        setYearVehicleData(year)
        
        const models = await getModels(brandVehicleData, year);
        setModelOptions(models);
        resetField('model');
        resetField('version');
      }
    }
    if (name === 'model') {
      const model = modelOptions.find((el: any) => el.value === value);
      if (model && brand !== 0) {
        const res = await getVersions(brand, model.id, yearVersions);

        setVersionOptions(res);

        resetField('version');

        if (VERSION.isLegacy) resetField('year');
      } else if (model && brandVehicleData !== 0) {
        const res = await getVersions(
          brandVehicleData,
          model.id,
          yearVehicleData
        );

        setVersionOptions(res);

        resetField('version');
      }
    }

    if (name === 'version') {
      const version: any = versionOptions.find((el: any) => el.value === value);
      if (version) {
        setCodia(version.codia);

        if (VERSION.isLegacy) {
          setYearOptions(version.years);

          resetField('year');
        }
      }
    }

    Dispatch(loadingDeactivate());
  };

  return (
    <Grid onSubmit={handleSubmit(onSubmit)} id="used-form">
      <Select
        errors={errors}
        register={register}
        name="brand"
        options={brandOptions}
        defaultValue=""
        placeholder="Seleccione una marca"
        label="Marca"
        handleSelect={handleSelect}
      />
      {VERSION.isLegacy ? (
        <>
          <Select
            errors={errors}
            register={register}
            name="model"
            options={modelOptions}
            defaultValue=""
            placeholder="Seleccione el modelo"
            label="Modelo"
            disabled={!watch('brand')}
            handleSelect={handleSelect}
          />
          <Select
            errors={errors}
            register={register}
            name="version"
            options={versionOptions}
            defaultValue=""
            placeholder="Seleccione la version"
            disabled={!watch('model')}
            label="Version"
            handleSelect={handleSelect}
          />
          <Select
            errors={errors}
            register={register}
            name="year"
            options={yearOptions}
            defaultValue=""
            placeholder="Seleccione el año"
            disabled={!watch('version')}
            label="Año"
          />
        </>
      ) : (
        <>
          <Select
            errors={errors}
            register={register}
            name="year"
            options={yearOptions}
            defaultValue=""
            placeholder="Seleccione el año"
            disabled={!watch('brand')}
            label="Año"
            handleSelect={handleSelect}
          />
          <Select
            errors={errors}
            register={register}
            name="model"
            options={modelOptions}
            defaultValue=""
            placeholder="Seleccione el modelo"
            label="Modelo"
            disabled={!watch('year')}
            handleSelect={handleSelect}
          />
          <Select
            errors={errors}
            register={register}
            name="version"
            options={versionOptions}
            defaultValue=""
            placeholder="Seleccione la version"
            disabled={!watch('model')}
            label="Version"
            handleSelect={handleSelect}
          />
        </>
      )}

      <TextField
        name="kilometers"
        register={register}
        errors={errors}
        type="text"
        placeholder="Kilometros"
        filter={numberRegexp}
        pattern={numberRegexp}
        required={true}
        disabled={!watch(VERSION.isLegacy ? 'year' : 'version')}
        label="Kilometros"
        limits={kilometers}
      />

      {!disabledFields?.patent && (
        <TextField
          name="patent"
          register={register}
          errors={errors}
          type="text"
          placeholder="Patente"
          filter={patentRegexp}
          required={true}
          disabled={!watch(VERSION.isLegacy ? 'year' : 'version')}
          errorMsg="Formato de patente aaa111 / aa111bb"
          label="Patente"
        />
      )}

      {additionalFields &&
        additionalFields.map((field: any, i: number) => {
          if (field.type === 'text' || field.type === 'number') {
            if (field?.size === 'fullWidth')
              return (
                <FullWidthRow>
                  <TextField
                    key={i}
                    name={field.name}
                    register={register}
                    errors={errors}
                    type={field.type}
                    placeholder={field.placeholder}
                    filter={new RegExp(field.filter)}
                    required={field.required ?? true}
                    disabled={!watch(VERSION.isLegacy ? 'year' : 'version')}
                    errorMsg="Error"
                    label={field.label}
                    limits={field.limits}
                  />
                </FullWidthRow>
              );
            return (
              <TextField
                key={i}
                name={field.name}
                register={register}
                errors={errors}
                type={field.type}
                placeholder={field.placeholder}
                filter={new RegExp(field.filter)}
                required={true}
                disabled={!watch(VERSION.isLegacy ? 'year' : 'version')}
                errorMsg="Error"
                label={field.label}
                limits={field.limits}
              />
            );
          } else if (field.type === 'select') {
            if (field?.size === 'fullWidth')
              return (
                <FullWidthRow>
                  <Select
                    key={i}
                    name={field.name}
                    register={register}
                    errors={errors}
                    options={field.options}
                    defaultValue=""
                    placeholder={field.placeholder}
                    disabled={!watch(VERSION.isLegacy ? 'year' : 'version')}
                    label={field.label}
                  />
                </FullWidthRow>
              );
            return (
              <Select
                key={i}
                name={field.name}
                register={register}
                errors={errors}
                options={field.options}
                defaultValue=""
                placeholder={field.placeholder}
                disabled={!watch(VERSION.isLegacy ? 'year' : 'version')}
                label={field.label}
              />
            );
          }
          return '';
        })}
    </Grid>
  );
}
