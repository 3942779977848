import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import TextField from './TextField';
import Select from './Select';
import { useForm, SubmitHandler } from 'react-hook-form';
import { devices } from '../utils/devices';

const FormContainer = styled<any>('div')`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(auto, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;

  @media ${devices.mobileL} {
    grid-template-columns: ${(p) =>
      p.fields === 1 ? 'repeat(1, 1fr)' : 'repeat(2, 1fr)'};
    grid-column-gap: 1rem;
    grid-row-gap: 0.25rem;
  }
`;

interface Props {
  fields: Array<Record<string, any>>;
  formTitle: string;
  id: string;
  handleForm: (values: Record<string, any>) => void;
  setValidForm: React.Dispatch<React.SetStateAction<boolean>>;
}

const Form = ({ fields, formTitle, handleForm, id, setValidForm }: Props) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm<any>({ mode: 'onChange' });
  const onSubmit: SubmitHandler<any> = (data) => handleForm(data);
  const userData = useSelector((state: any) => state.user.value);

  useEffect(() => {
    if (isValid) {
      setValidForm(true);
    } else {
      setValidForm(false);
    }
  }, [isValid]);

  useEffect(() => {
    if (Object.keys(userData).length > 0 && fields) {
      const resetValues: Record<string, any> = {};
      fields.forEach((field) => {
        resetValues[field.name] = userData[field.name];
      });
      reset(resetValues);
    }
  }, [userData, fields]);

  const formFields: any = [];
  fields &&
    fields.forEach((field) => {
      let element;
      if (field.content === 'field') {
        element = (
          <TextField
            key={field.name}
            placeholder={field.placeholder}
            disabled={false}
            type={field.type}
            register={register}
            errors={errors}
            name={field.name}
            label={field.label}
            prefix={field.prefix}
            required={true}
            errorMsg={field.fieldSettings && field.fieldSettings.errorMessage}
            filter={new RegExp(field.filter)}
            pattern={new RegExp(field.pattern)}
          ></TextField>
        );
      } else {
        element = (
          <Select
            key={field.name}
            errors={errors}
            register={register}
            name={field.name}
            options={field.options}
            defaultValue=""
            placeholder={field.placeholder}
            label={field.label}
          />
        );
      }
      formFields.push(element);
    });

  return (
    <form onSubmit={handleSubmit(onSubmit)} id={id}>
      <FormContainer fields={fields && fields.length}>
        {formFields}
      </FormContainer>
    </form>
  );
};
export default Form;
