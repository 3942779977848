import styled from 'styled-components';
import formatRoundedCurrency from '../../utils/formatCurrency';
import { useSelector } from 'react-redux';

const InfoContainer = styled.div`
  flex: 1;
  text-align: left;
  display: flex;
  padding: 0.5rem 1rem;
  background: #f4f4f4;
  border-radius: 5px;
  margin-bottom: 0.5rem;
`;

const AverageValueContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.5rem;
  justify-content: space-around;
  flex-direction: row;
  background: #f4f4f4;
  border-radius: 5px;
  margin-bottom: 0.5rem;
  padding: 0.75rem 0rem;
  & .value {
    font-size: medium;
    font-weight: 600;
    line-height: 1.25;
    margin: 0;
  }
  & .label {
    font-size: small;
    line-height: 1;
    margin: 0;
  }
  & .left {
    text-align: left;
  }
  & .right {
    text-align: right;
  }
  & .center {
    text-align: center;
  }
`;

const VehicleInfo = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  flex-wrap: wrap;
  padding: 0.5rem, 0.5rem;
  & > p {
    font-size: small;
    margin: 0 1rem 0.5rem 0;
    & > span {
      font-weight: 600;
      color: #5c5c5c;
      font-size: small;
      text-transform: capitalize;
    }
  }
  & > h2 {
    font-size: medium;
    font-weight: 600;
    margin: 0;
    margin-bottom: 0.5rem;
    width: 100%;
  }
`;

export default function CalculatorView({ disabledFields }) {
  const dataForms = useSelector((state) => state.user.value);
  const { vehicle_data, prices } = useSelector((state) => state.tradeIn.value);
  const config = useSelector((state) => state.config.value);

  const { additionalFields } = config.steps[0];

  const listFields = additionalFields && findAdditionalFields(additionalFields);

  function findAdditionalFields(additionalFields) {
    const fields = {};
    Object.entries(vehicle_data).forEach(([key, value]) => {
      additionalFields.forEach((field) => {
        field.name === key && (fields[key] = value);
      });
    });
    return fields;
  }

  const renderAdditionalFields = (fields) => {
    let infoList = [];
    for (let [key, value] of Object.entries(fields)) {
      infoList.push(
        <p key={key}>
          <span>{key.replace(/-/g, ' ')}:</span> {value}
        </p>
      );
    }
    return infoList;
  };

  return (
    <div
      style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <AverageValueContainer>
        <div className="center">
          <p className="label">Mínimo</p>
          <p className="value">${formatRoundedCurrency(prices.minimumPrice)}</p>
        </div>
        <div className="center">
          <p className="label">Promedio</p>
          <p className="value">${formatRoundedCurrency(prices.averagePrice)}</p>
        </div>
        <div className="center">
          <p className="label">Máximo</p>
          <p className="value">${formatRoundedCurrency(prices.maximumPrice)}</p>
        </div>
      </AverageValueContainer>
      <InfoContainer>
        <VehicleInfo>
          <h2>Datos del vehiculo:</h2>
          <p>
            <span>Modelo: </span>
            {vehicle_data.brand} {vehicle_data.model} {vehicle_data.version}
          </p>
          <p>
            <span>Año: </span>
            {vehicle_data.year}
          </p>
          <p>
            <span>Kilometros: </span>
            {formatRoundedCurrency(vehicle_data.kilometers)} km
          </p>
          {!disabledFields?.patent && (
            <p>
              <span>Patente: </span> {vehicle_data?.patent}
            </p>
          )}
          {listFields && renderAdditionalFields(listFields)}
          {dataForms && (
            <>
              <h2>Datos del propietario:</h2>
              {renderAdditionalFields(dataForms)}
            </>
          )}
        </VehicleInfo>
      </InfoContainer>
    </div>
  );
}
