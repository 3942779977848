import { useState } from 'react';
import styled from 'styled-components';
import { devices } from '../utils/devices';

const CheckboxContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  @media ${devices.mobileM} {
    margin-bottom: 0;
  }
`;
const StyledCheckbox = styled.input.attrs({ type: 'checkbox' })`
  display: none;
  &:checked {
    + label {
      span {
        background-color: ${(props) => props.theme.primaryColor};
        transform: scale(1.25); // enlarge the box
        border: 2px solid ${(props) => props.theme.primaryColor};

        &:after {
          width: 10px;
          background: #fff;
          transition: width 150ms ease 100ms; // enlarge the tick
        }

        &:before {
          width: 5px;

          background: #fff;
          transition: width 150ms ease 100ms; // enlarge the tick
        }
      }

      &:hover {
        // copy the states for onMouseOver to avoid flickering
        span {
          background-color: ${(props) => props.theme.primaryColor};
          transform: scale(1.25); // enlarge the box
          &:after {
            width: 10px;
            background: #fff;
            transition: width 150ms ease 100ms; // enlarge the tick
          }

          &:before {
            width: 5px;
            background: #fff;
            transition: width 150ms ease 100ms; // enlarge the tick
          }
        }
      }
    }
  }
`;
const Label = styled.label`
  color: #4c4c4c;
  font-size: 0.8125rem;
  font-weight: 550;
  margin: auto;
  position: relative; // to make it easier to click
  cursor: pointer;
  & > span {
    display: inline-block;
    position: relative;
    background-color: #d5d5d5;
    width: 20px;
    height: 20px;
    transform-origin: center;
    border: 2px solid #d5d5d5;
    vertical-align: -6px;
    margin-right: 10px;
    transition: background-color 150ms 200ms,
      transform 350ms cubic-bezier(0.78, -1.22, 0.17, 1.89);
    &:before {
      content: '';
      width: 0px;
      height: 2px;
      border-radius: 2px;
      background: ${(props) => props.theme.primaryColor};
      position: absolute;
      transform: rotate(45deg);
      top: 7px; // you'll need to experiment with placement depending on the dimensions you've chosen
      left: 4px; // you'll need to experiment with placement depending
      transition: width 50ms ease 50ms;
      transform-origin: 0% 0%;
    }
    &:after {
      content: '';
      width: 0px;
      height: 2px;
      border-radius: 2px;
      background: ${(props) => props.theme.primaryColor};
      position: absolute;
      transform: rotate(305deg);
      top: 11px; // you'll need to experiment with placement depending on the dimensions you've chosen
      left: 6px; // you'll need to experiment with placement depending on the dimensions you've chosen
      transition: width 50ms ease;
      transform-origin: 0% 0%;
    }
  }

  &:hover {
    span {
      &:before {
        width: 6px;
        transition: width 100ms ease;
      }

      &:after {
        width: 11px;
        transition: width 150ms ease 100ms;
      }
    }
  }
`;
const Link = styled.a`
  color: ${(props) => props.theme.primaryColor};
  &:hover {
    color: ${(props) => props.theme.primaryColorHover};
  }
`;

export default function Checkbox({ setIsChecked, data }) {
  const [isChecked, setChecked] = useState(false);
  const handleOnChange = () => {
    setChecked(!isChecked);
    setIsChecked(!isChecked);
  };

  return (
    <CheckboxContainer>
      <StyledCheckbox
        type="checkbox"
        checked={isChecked}
        onChange={handleOnChange}
        id="check"
      />
      <Label htmlFor="check" checked={isChecked}>
        <span></span>
        {data.label}{' '}
        {data && (
          <Link href={data.link.url} target="_blank">
            {data.link.label}
          </Link>
        )}
      </Label>
    </CheckboxContainer>
  );
}
