import styled from 'styled-components';
import { devices } from '../utils/devices';

const LogoContainer = styled.div`
  width: auto;
  opacity: 0.6;
  position: absolute;
  top: 1.5rem;
  right: 1rem;
  @media ${devices.mobileL} {
    top: 1rem;
  }
`;
const LogoImg = styled.img`
  width: 100px;
  height: auto;
`;
//Se harcodea el logo hasta que se use la config traída del back

export default function Logo({ logoUrl = '' }: { logoUrl: string }) {
  return (
    <LogoContainer>
      <LogoImg src={logoUrl} alt="logo" />
    </LogoContainer>
  );
}
